<div class="contact container-fluid">
    <section class="ftco-section">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-md-6 text-center mb-3"
                    style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;margin-left: 1rem;">
                    <h2 class="borderLine" translate>contact</h2>
                </div>
            </div>
            <div class="boxstyle row justify-content-center">
                <div class="col-lg-9 col-md-12">
                    <div class="wrapper">
                        <div class="row no-gutters">
                            <div class="col-md-7 d-flex align-items-stretch">
                                <div class="contact-wrap w-100 p-md-5 p-4" style="box-shadow: 18px 2px 18px gray;">
                                    <h3 class="mb-4" translate>form</h3>
                                    <div id="form-message-warning" class="mb-4"></div>
                                    <div id="form-message-success" class="mb-4">
                                        Your message was sent, thank you!
                                    </div>
                                    <form id="contactForm" name="contactForm">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="name" id="name"
                                                    [placeholder]="'name-placeholder' | translate">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="email" class="form-control" name="email" id="email"
                                                        placeholder="Email">
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="subject" id="subject"
                                                        [placeholder]="'topic' | translate">
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <textarea name="message" class="form-control" id="message" cols="30"
                                                        rows="7" [placeholder]="'message' | translate"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel"
                                                    [(ngModel)]="token" required
                                                    [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                                                <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
                                                    class="invalid-feedback">
                                                    <div *ngIf="recaptcha.errors?.required" translate>required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="margin-top: 1rem;">
                                                <div class="form-group">
                                                    <input type="submit" [value]="'send' | translate" class="btn btn-primary">
                                                    <div class="submitting"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-5 d-flex align-items-stretch">
                                <div class="info-wrap bg-primary w-100 p-lg-5 p-4" style="box-shadow: 15px 2px 18px gray;">
                                    <h3 class="mb-4 mt-md-4" translate>cdetails</h3>
                                    <div class="dbox w-100 d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="material-icons">pin_drop</span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span translate>address</span><br> <a translate>street</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="dbox w-100 d-flex align-items-center">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="material-icons">phone</span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span>Tel.:</span> <br> <a href="tel://2467027537"> 2467027537 - 2467028981</a></p>
                                        </div>
                                    </div>
                                    <div class="dbox w-100 d-flex align-items-center">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="material-icons">alternate_email</span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span>Email:</span> <br>
                                                <a href="mailto:kastcham@otenet.gr">kastcham@otenet.gr</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="dbox w-100 d-flex align-items-center">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="material-icons">schedule</span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span translate>time</span> <br> <a href="#">8:00 - 14:00</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       
    </section>
     <div class="container-fluid">
            <div class="row map">

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7213.7512084086065!2d21.263941296274606!3d40.519297333942305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1359fd3b8684dbe1%3A0x168354f9c6899034!2zzpXOoM6ZzpzOlc6bzpfOpM6XzqHOmc6fIM6azpHOo86kzp_Ooc6ZzpHOow!5e0!3m2!1sel!2sgr!4v1689342463882!5m2!1sel!2sgr"
                    class="w-100" height="400" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
</div>