<div class="content container">
    <div class="cont row" style="height: 406px;">
        <div class="left col-md-5">
            <div style="padding-left: 3rem;color: #f8f8f8;font-weight: bold;">
                <p style="margin-bottom:0px" translate>welcome</p>
                <h1 class="h1" translate>chamber</h1>
                <a class="buttonBlue" style="padding: 0.8rem;text-decoration: none;" href="https://echamber.kastoriachamber.gr/" target="blank" translate>echamber</a>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row" style="padding-top: 2rem;padding-bottom: 4rem;">
        <div class="col-md-12">
            <h2 class="borderLine" translate>services</h2>
            <p translate>servicesDesc</p>
        </div>
        <div class="col-md-12">
            <div class="container-fluid">
                <div class="js row">

                    <a class="card col-md-3 col-sm-12 box2" style="background-color: #F2F3F7;text-decoration: none;"
                        href="https://eyms.businessportal.gr/auth" target="_blank">

                        <div class="card">
                            <div class="card-side front">
                                <div><img class="iconBox2" src="/assets/Icon.png"></div>
                                <h5 class="servicesKEE" translate>onestop</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>onestopdesc</p>
                            </div>
                        </div>

                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;;"
                        href="https://www.businessportal.gr/" target="_blank">

                        <div class="card">
                            <div class="card-side front">
                                <div><img class="iconBox2" src="/assets/Business.png"></div>
                                <h5 class="servicesKEE" translate>gemi</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>gemidesc</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" href="https://events.kastoriachamber.gr/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="material-icons iconBox2">event_available</i></div>
                                <h5 class="servicesKEE" translate>events</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>eventsdesc</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" href="https://seminars.kastoriachamber.gr/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="material-icons iconBox2">local_library</i></div>
                                <h5 class="servicesKEE" translate>seminars</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>seminarsdesc</p>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- <div class="js row">


                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;"
                        href="https://businessadvice.lesvos-chamber.com/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="material-icons iconBox2">quiz</i></div>
                                <h5 class="servicesKEE">Υποβολή Ερωτημάτων</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph">Πλατφόρμα υποβολής ερωτημάτων προς το Επιμελητήριο Λέσβου</p>
                            </div>
                        </div>
                    </a>

                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" href="https://kb.lesvos-chamber.com/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="material-icons iconBox2">groups</i></div>
                                <h5 class="servicesKEE">Επιχειρηματική Πληροφόρηση</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph">Παραλαμβάνει εύρεση πληροφοριών για διάφορες διοικητικές
                                    διαδικασίες.</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" href="https://events.lesvos-chamber.com/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="material-icons iconBox2">event_available</i></div>
                                <h5 class="servicesKEE">Διαχείριση Εκδηλώσεων</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph">Εκδηλώσεις του Επιμελητηρίου Λέσβου</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" href="https://seminars.lesvos-chamber.com/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="material-icons iconBox2">local_library</i></div>
                                <h5 class="servicesKEE" >Διαχείριση Σεμιναρίων</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph">
                                    Το Επιμελητήριο Λέσβου, στα πλαίσια της συνεχούς επιμόρφωσης των στελεχών των
                                    επιχειρήσεων
                                    μελών του, και όχι μόνο, διοργανώνει επιμορφωτικά σεμινάρια.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="js row">
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;"
                    href="https://katartisi.lesvos-chamber.com/" target="_blank">
                    <div class="card">
                        <div class="card-side front">
                            <div><i class="material-icons iconBox2">person_search</i></div>
                            <h5 class="servicesKEE">Κατάρτιση - Συμβουλευτική - Πιστοποίηση</h5>
                        </div>
                        <div class="card-side back">
                            <p class="paragraph">Κατάρτιση, Συμβουλευτική & Πιστοποίηση 150 εργαζομένων του ιδιωτικού τομέα και Αυτοαπασχολουμένων στη Λέσβο και στη Λήμνο</p>
                        </div>
                    </div>
                </a>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row" style="padding: 2rem; background: #F9FAFB;">
        <div class="col-md-7">
            <div class="box3">
                <h2 style="font-weight: bold;font-size: 40px;text-align: left;" translate>infomaterial</h2>
                <p class="paragraph" style="text-align: left;font-size: 18px;" translate>infomaterialdesc</p>
                <div style="display: flex;">
                    <button class="buttonBlue" style="display: flex;" routerLink="/category/1" translate>invitations</button>
                    <button class="buttonBlue" style="display: flex;margin-left: 1rem;" routerLink="/category/1" translate>proclamations</button>
                </div>

            </div>


        </div>
        <div class="col-md-5" style=" background: #F9FAFB;">
            <!-- <div style="display: flex;justify-content: flex-end;">
                <img class="img-fluid" src="/assets/CTA section.png">
            </div> -->

            <div class="desktop container-fluid">
                <div class="row">
                    <div class="col-md-4 offset-md-2" style="display: flex;justify-content: flex-end;align-items: end;"><img class="img-fluid" src="/assets/Image.png"></div>
                    <div class="col-md-4"><img class="img-fluid" src="/assets/Image@2x.png"></div>
                </div>
                <div class="row" style="margin-top: 1rem;">
                    <div class="col-md-4"><img class="img-fluid" src="/assets/Image(1).png"></div>
                    <div class="col-md-4"><img class="img-fluid" src="/assets/Image(2).png"></div>
                    <div class="col-md-4"><img class="img-fluid" src="/assets/Image(3).png"></div>
                </div>
            </div>

            <div class="mobile container-fluid">
                <div class="row" >
                    <div class="col-md-6" style="display: flex;align-items: center;"><img class="img-fluid" src="/assets/Image.png"></div>
                    <div class="col-md-6" style="display: flex;align-items: center;"><img class="img-fluid" src="/assets/Image@2x.png"></div>
                </div>
                <div class="row">
                    <div class="col-md-3"><img class="img-fluid" style="margin: 1rem;" src="/assets/Image(1).png"></div>
                    <div class="col-md-4"><img class="img-fluid" style="margin: 1rem;" src="/assets/Image(2).png"></div>
                    <div class="col-md-3"><img class="img-fluid" style="margin: 1rem;" src="/assets/Image(3).png"></div>
                </div>
            </div>

        </div>
    </div>

  

    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-12">
            <h2 class="borderLine" routerLink="/category/1" translate>latestnews</h2>
        </div>
    </div>

    <div class="row" style="margin-top: 2rem;margin-bottom:2rem; cursor: pointer;" >
        <div class="col-md-4" style="cursor: pointer;" routerLink="/page/{{item.id}}" *ngFor="let item of latestNews">
            <img class="img-fluid" style="object-fit: cover;max-width: 20rem;height: 8rem;" [src]="item.cover">
            <p style="margin-top: 1rem;">{{item.attributes.published}}</p>
            <h5 style="font-size: 1rem;">{{item.attributes.title}}</h5>
           
        </div>
    </div>
    <div class="row" style="padding-top: 2rem;padding-bottom: 4rem;">
        <div class="col-md-12">
            <h2 class="borderLine" translate>freeServices</h2>
            <p translate>servicesDesc</p>
        </div>
        <div class="col-md-12">
            <div class="container-fluid">
                
                <div class="js row">

                    <a class="card col-md-3 col-sm-12 box2" style="background-color: #F2F3F7;text-decoration: none;"
                        href="https://insuranceregistry.uhc.gr/" target="_blank">

                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa-solid fa-shield-halved"></i></div>
                                <h5 class="servicesKEE" translate>insuranceregistry</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>insuranceregistrydesc</p>
                            </div>
                        </div>

                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;;"
                        href="https://elearning.uhc.gr/" target="_blank">

                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa fa-chalkboard-user"></i></div>
                                <h5 class="servicesKEE" translate>elearning</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>elearningdesc</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;;"
                        href="https://businessplatform.uhc.gr/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                              
                                <div><i class="iconBox3 fa-solid fa-magnifying-glass-dollar"></i></div>
                                <h5 class="servicesKEE" translate>businessplatform</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>businessplatformdesc</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;;"
                        href="https://europa.eu/youreurope/business/index_el.htm" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                
                                <div><i class="iconBox3 fa-solid fa-earth-europe"></i></div>
                                <h5 class="servicesKEE" translate>ureurope</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>ureuropedesc</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="js row">


                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;"
                        href="https://www.directmarket.gr/dmarket/marketplc/index.jsp?chambercd=52" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa fa-cart-shopping"></i></div>
                                <h5 class="servicesKEE" translate>directmarket</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>directmarketdesc</p>
                            </div>
                        </div>
                    </a>

                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfpv67i80cLDycq21aBSzV3aTMefjOvf3C_RoUbXzVxt2XNkQ/viewform?uiv=1">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa-solid fa-users-gear"></i></div>
                                <h5 class="servicesKEE" translate>promoteservices</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>promoteservicesdesc</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" target="_blank" href="https://www.filoxeno.com/hotels/el/%CE%9A%CE%B1%CF%83%CF%84%CE%BF%CF%81%CE%B9%CE%AC">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa-solid fa-map-location-dot"></i></div>
                                <h5 class="servicesKEE">filoxeno.com</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph" translate>filoxenodesc</p>
                            </div>
                        </div>
                    </a>
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;" target="_blank" href="https://warranty.uhc.gr/">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa-solid fa-people-roof"></i></div>
                                <h5 class="servicesKEE" translate>warranty</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph">
                                    
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="js row">
                    <a class="card col-sm-12 col-md-3 box2" style="background-color: #F2F3F7;text-decoration: none;"
                        href="https://womeninbusiness.uhc.gr/" target="_blank">
                        <div class="card">
                            <div class="card-side front">
                                <div><i class="iconBox3 fa-solid fa-venus"></i></div>
                                <h5 class="servicesKEE" translate>womeninbusiness</h5>
                            </div>
                            <div class="card-side back">
                                <p class="paragraph"></p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-10">
             <h2 class="borderLine" translate>eventsDays</h2>
             <p translate>learnMoreEvents</p>
        </div>
        <div class="m1 col-md-2">
            <button class="buttonBlue" routerLink="/category/5/1" translate>more</button></div>
            <!-- <a style="border-radius: 8px;
            border: 1px solid #026AA2;
            background: #026AA2;
            padding: 10px 10px; 
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);color: white;text-decoration: none;"
                routerLink="/category/2/1" translate>more</a></div> -->
       
        <div class="cal col-md-4" >
            <!-- <p-calendar [(ngModel)]="date" [inline]="true" [locale]="locale" dateFormat="dd.mm.yy" (onSelect)="click(date)"></p-calendar> -->
            <p-calendar [(ngModel)]="date" [inline]="true" [showOtherMonths]='false' class="max-w-full"
                (onMonthChange)="onMonthChange($event)" (onSelect)="onSelect(date)">
                <ng-template pTemplate="date" let-date>
                    <span [ngClass]="checkDateForHoliday(date) ==true ? 'eventDay' : 'normalDay'">{{date.day}}</span>
                </ng-template>
            </p-calendar>
        </div>
        <div class="col-md-8">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4" style="cursor: pointer;" routerLink="/page/{{item.id}}" *ngFor="let item of events">
                        <img class="img-fluid" style="object-fit: cover;height: 10rem;" [src]="item.cover">
                        <p style="margin-top: 1rem;">{{item.attributes.published}}</p>
                        <h5 style="font-size: 1rem;">{{item.attributes.title}}</h5>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <div class="row" style="background: #008edc;">
        <div class="content3 col-md-3">
            <div><i class="material-icons iconBox2">group</i></div>
            <h5>{{members?.attributes.active_number}}</h5>
            <p>{{members?.attributes.active_text}}</p>
        </div>
        <div class="content3 col-md-3">
            <div><i class="material-icons iconBox2">person_add</i></div>
            <h5>{{members?.attributes.new_number}}</h5>
            <p>{{members?.attributes.new_text}}</p>
        </div>
        <div class="content3 col-md-3">
            <div><i class="material-icons iconBox2">person_remove</i></div>
            <h5>{{members?.attributes.removed_number}}</h5>
            <p>{{members?.attributes.removed_text}}</p>
        </div>
        <div class="content3 col-md-3">
            <div><i class="material-icons iconBox2">insights</i></div>
            <h5>{{members?.attributes.balance_number}}</h5>
            <p>{{members?.attributes.balance_text}}</p>
        </div>
    </div>

</div>