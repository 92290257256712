<div class="container">
    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-12">
            <h2 class="borderLine">{{category && category.attributes.name}}</h2>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem;margin-bottom: 2rem;" >
        <div class="col-md-6" *ngFor="let item of pages?.data">
            <div class="container-fluid">
                <div class="row">
                    <div class="picture col-md-4">
                        <img class="picture img-fluid" [src]="item.cover">
                    </div>
                    <div class="content col-md-8">
                        <h5 class="header">{{item.attributes.title}}</h5>
                        <p class="paragraph">{{item.attributes.short_description}}</p>
                        <a class='link' routerLink="/page/{{item.id}}">Περισσότερα</a>
                    </div>
                </div>

            </div>
        </div>
        <div class="row justify-content-end" style="margin-top: 2vh;">
            <p-paginator [first]="first" [rows]="1" #paginator [totalRecords]="numberOfPages"
                (onPageChange)="paginate($event)">
            </p-paginator>
        </div>
        


    </div>
</div>