import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';


@Injectable({
    providedIn: 'root'
})

export class ApiService {
    private path = '';

    constructor(private http: HttpClient) { }

    getMenus(locale: string) {
        this.path = `api/menus?locale=${locale}&populate=%2A&pagination[limit]=-1`
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }


    getSliders() {
        this.path = `api/sliders/?populate=%2A&pagination[limit]=-1`
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getVideos() {
        this.path = `api/youtube-videos/`

        return this.http.get<any[]>(environment.apiUrl + this.path).toPromise();
    }

    getPage(id: any) {
        this.path = `api/pages/${id}/?populate=%2A`

        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getMagazines(page) {
        this.path = `api/magazines?populate=%2A&pagination[page]=${page}&pagination[pageSize]=9&sort[0]=date%3Adesc`

        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getMagazine(id: any) {
        this.path = `api/magazines/${id}/?populate=%2A`

        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getCategory(id: any) {
        this.path = `api/categories/${id}/?populate[pages][populate][0]=cover`

        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getCategory2(id: any, limit: any, locale: any, page: any) {
        this.path = `api/pages/?populate=*&filters[categories][id][$eq]=${id}&pagination[page]=${page}&pagination[pageSize]=${limit}&locale=${locale}&sort[0]=published%3Adesc`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getBanners() {
        this.path = `api/banners/?populate=%2A&pagination[limit]=-1`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getEvents(id: any, locale: any, from: any, to: any) {
        
        this.path = `api/pages/?filters[event_date][$gte]=${from}&filters[event_date][$lte]=${to}&locale=${locale}`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }


    search(locale: string, query: any, page: any, pageSize: any) {
        this.path = `api/pages/?sort=published%3Adesc&locale=${locale}&${query}&populate=cover&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getEkdoseis(locale: string) {
        this.path = `api/ekdosi/?locale=${locale}&populate=%2A`;
        return this.http.get<any[]>(environment.apiUrl + this.path)

    }

    getLinks(locale: string) {
        this.path = `api/link/?locale=${locale}&populate=%2A`;
        return this.http.get<any[]>(environment.apiUrl + this.path)

    }

    getMembers(locale: string) {
        this.path = `api/member/?locale=${locale}&populate=%2A`;
        return this.http.get<any[]>(environment.apiUrl + this.path)

    }

    getPages(locale: string) {
        this.path = `api/pages/?locale=${locale}&populate=%2A&pagination[limit]=-1`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    postEmail(obj: any) {
        this.path = `https://cms.eeth.gr/api/email/`;
        return this.http.post<any>(this.path, obj);
    }


    getActiveBusinesses(from: any, to:any) {
        this.path = `api/observatory/activeBusinessesCount?from=${from}&to=${to}`;
        return this.http.get<any>(environment.chamberUrl + this.path);
    }

    getEggrafesDiagraffes(from: any, to:any) {
        this.path = `api/observatory/proeggrafesEggrafesDiagrafes?from=${from}&to=${to}`;
        return this.http.get<any[]>(environment.chamberUrl + this.path);
    }
}